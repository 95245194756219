body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.right {
  float: right;
}

.pointer {
  cursor: pointer;
}

.form-row {
  padding: 8px;
}

.pharCard > .card-header {
  /* background-color: rgb(91, 146, 9); */
  background-color: rgb(0, 175, 0);
  color: #ffffff;
}

.pharCard {
  border-color: rgb(0, 175, 0) !important;
  margin: 4px;
}
