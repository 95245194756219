#headerTitle {
  padding: 16px;
  width: 100%;
  font-size: 32px;
}

@media (max-width: 948px) {
  #headerTitle > #appender {
    display: none;
  }
}
